<template>
	<div class="ysm-order-three">
		<!-- 信息 -->
		<div align="left" class="left"  >
				<img src="../../assets/ysmimg/ysmorderoneimg.png" width="90px" height="110px" style="vertical-align: middle;" >
				<div>
					<h3 data-i18n="[html]forms.order.patient_information">原始治疗目标</h3>
					<p data-i18n="forms.order.patient_information_content">请在此处写下您的治疗目标</p>
					<div v-show="status==2" style="height: 100%;">
						<div v-for="(item,index) in zhiLiaoMuBiao" style="background-color: #F0F0EE;border-radius: 5px;min-width: 25rem;padding-left: 20px;padding-top: 10px;padding-bottom: 10px;margin-bottom: 10px;padding-right:10px">
							<p>{{item.title}}</p>
							<p>{{item.content}}</p>
							<p v-if="item.answered==true && item.answer!='ok'">不同意:{{item.createdTime}}</p>
							<p v-if="item.answered==true && item.answer=='ok'">已同意:{{item.createdTime}}</p>
							<p v-if="item.answered==true && item.answer!='ok'">不同意原因:<br/>{{item.answer}}</p>
						</div>
						<div id="" v-show="showTongyi">
							<div id="zlcon1" style="margin-top: 0.625rem;z-index: 0; width: 500px !important;height: 240px!important;"></div>
							<div style="display: flex;justify-content: space-between;align-items: center;width: 500px;position: relative;top: -90px;">
								<div style="width: 508px;"><span style="color: red;display: inline-block;" v-show="showBTYWarn">编辑内容不能超过35个字</span></div>
								<span>{{BuTongYiwordNumber}}/35</span>
							</div>
							<div align="center" style="position: relative;top: -60px;">
								<el-button class="nextStep" type="primary" @click="tongyi">同意</el-button>
								<el-button class="nextStep" type="primary" @click="notongyi">不同意</el-button>
							</div>
						</div>
						
					</div>
				</div>
				
				
		</div>
		<div class="con" >
		<div v-show="status==0||status==1" class="right"  align="left" style="padding-left: 20px;">
			<span>需要什么样的治疗?<span style="color: red;">*</span></span>
			<br />
			<div class="selzl" style="margin-top: 0.625rem;margin-bottom: 1.875rem;">
				
				<el-checkbox v-model="mxchecked" @change="mx()">纯粹的美学治疗<img @click="showMsg" src="../../assets/ysmimg/wenhao.png" style="position: relative;top: 10px;width: 30px;height: 32px;" /> </el-checkbox><br /><br />
				<el-checkbox v-model="zjchecked" @change="zj()">正畸治疗</el-checkbox>
			
			</div>
			<span>请具体说明应该治疗什么?<span style="color: red;">*</span></span>
			<div id="zlcon" style="margin-top: 0.625rem;z-index: 0;"></div>
			<span>*为必填项&nbsp;<span style="color: red">(编辑内容不能超过80个字)</span></span>
			<span class="wordNumber">{{TiLength}}/80</span>
			<div align="right" style="margin-top: 20px;">
				<el-button class="back" type="primary" @click="back">返回</el-button>
				<el-button class="nextStep" type="primary" @click="nextStepOrder">下一步</el-button>
			</div>
		</div>
		<div v-show="status==2" align="left" style="padding-left: 20px;">
			<!-- <span>需要什么样的治疗?<span style="color: red;">*</span></span> -->
			<br />
			<div class="selzl" style="margin-top: 0.625rem;margin-bottom: 1.875rem;">
				<!-- <el-checkbox v-model="mxchecked" @change="mx()">纯粹的美学治疗</el-checkbox>
				<el-checkbox v-model="zjchecked" @change="zj()">正畸治疗</el-checkbox> -->
			    <div style="margin-top: 10px;">
					<span style="font-weight: bolder;">原始目标:</span>
					<span style="display: block;padding-left: 10px;width: 413px;">{{mubiao}}</span>
				</div> 
			</div>
			<div v-if="showWenzi" style="color: green;font-weight: bolder;margin-bottom: 10px;">管理员已同意该治疗目标</div>
		</div>
		</div>
	</div>
</template>

<script>
	import E from 'wangeditor';
	import EE from 'wangeditor';
	export default{
		data:function(){
			return{
				BuTongYiwordNumber:0,
				showBTYWarn:false,
				TiLength:0,
				showWenzi:false,
				showTongyi:true,
				mubiao:'',
				mxchecked:false,
				zjchecked:false,
				editor:'',
				editor1:'',
				ruleForm:{
					oid:'',
					leixing:'',
					mubiao:''
				},
				zhiLiaoMuBiao:[],
				status:0
			}
		},
		methods:{
			//单击问号
			 showMsg(){
				this.$alert("请注意，进行单纯的美学治疗时，仅治疗您指出的那些错颌畸形问题。在某些情况下，这可能会导致有关咬合关系和牙尖交错咬合的后续问题。如果我们的临床合作伙伴认为这是严重问题，我们保留拒绝此类订单的权利。","美容治疗");
			},
			//下一步
			nextStepOrder(){
				let ins=this.editor.txt.text();
				this.ruleForm.mubiao=ins;
				this.$axios.post("/client/order/addYsmGoal",JSON.stringify(this.ruleForm),{
					headers:{
						"Content-type":"application/json"
					}
				}).then(res=>{
					this.$store.commit("setYsmStepIndex",4);
					this.$router.push("/ysmmain/ysmorder/ysmorderfour");
				}).catch(err=>{
					this.$message.error("添加失败");
				})
				this.$router.push("ysmorderfour");
			},
			mx(){
				this.mxchecked=true;
				this.zjchecked=false;
				this.ruleForm.leixing="纯粹的美学治疗"
			},
			zj(){
				this.mxchecked=false;
				this.zjchecked=true;
				this.ruleForm.leixing="正畸治疗"
			},
			//同意
			tongyi(){
				this.$confirm('确定同意吗?', '提示', {
				         confirmButtonText: '确定',
				         cancelButtonText: '取消',
				         type: 'warning'
				       }).then(() => {
				this.$axios.get("/client/order/agreeOrder",{
					params:{
						oid:this.ruleForm.oid
					}
				}).then(res=>{
					if (res.data.code == 200) {
						this.$alert("你已经同意","提示");
						//根据oid查询目标列表
						this.$axios.get("/client/order/queryGoals", {
							params: {
								oid: this.ruleForm.oid
							}
						}).then(res => {
							let orderInfo = res.data.data;
							
							this.zhiLiaoMuBiao = orderInfo.goals;
							this.showTongyi=false;
							this.showWenzi=orderInfo.showWenzi;
						}).catch(err => {
							console.log(err);
						});
						this.editor1.txt.text("");
					}
					
				}).catch(err=>{
					this.$message({
						type:"error",
						message:"请联系管理员",
						duration:1000,
						offset:300
					});
					// this.$router.push("/ysmmain/ysmlatestorder")
				});
				}).catch(() => {
				  this.$message({
				    type: 'info',
				    message: '取消了同意操作'
				  });          
				});
			},
			//不同意
			notongyi(){
				this.$confirm('确定不同意吗?', '提示', {
				         confirmButtonText: '确定',
				         cancelButtonText: '取消',
				         type: 'warning'
				       }).then(() => {
				let ins=this.editor1.txt.text();
				console.log(ins);
				if(ins==""){
					this.$alert('修改意见不能为空', '提示', {
						confirmButtonText: '确定',
						callback: action => {
							// this.$router.push("/ysmmain/ysmorder/ysmorderone");
						}
					});
				}else{
					this.$axios.get("/client/order/disagreeOrder",{
						params:{
							oid:this.ruleForm.oid,
							mubiao:ins
						}
					}).then(res=>{
						if(res.data.code==200){
						
							this.$alert('修改意见成功', '提示', {
								confirmButtonText: '确定',
								callback: action => {
									//根据oid查询目标列表
									this.$axios.get("/client/order/queryGoals", {
										params: {
											oid: this.ruleForm.oid
										}
									}).then(res => {
										let orderInfo = res.data.data;
										this.zhiLiaoMuBiao = orderInfo.goals;
										this.showTongyi=false;
										this.showWenzi=orderInfo.showWenzi;
										
									}).catch(err => {
										console.log(err);
									});
									this.editor1.txt.text("");
									
								}
							});
							
						}
						
					}).catch(err=>{
						this.$alert('请联系管理员', '提示', {
							confirmButtonText: '确定',
							callback: action => {
							}
						});
					});
				}
				}).catch(() => {
				  this.$message({
				    type: 'info',
				    message: '取消不同意操作'
				  });          
				});
			},
			//返回
			back(){
				this.$store.commit("setYsmStepIndex",2);
				this.$router.push("/ysmmain/ysmorder/ysmordertwo");
			},
		},
		mounted:function(){
			let oid=sessionStorage.getItem("oid");
			//根据oid查询目标列表
			// this.$axios.get("/client/order/queryGoals",{
			// 	params:{
			// 		oid:this.ruleForm.oid
			// 	}
			// }).then(res=>{
			// 	let orderInfo=res.data.data;
			// 	this.zhiLiaoMuBiao = orderInfo.goals;
			// 	this.showTongyi=false;
			// 	this.showWenzi=orderInfo.showWenzi;
			// 	console.log(orderInfo);
			// }).catch(err=>{
			// 	console.log(err);
			// });
			this.editor = new E('#zlcon')
			let _this=this;
			this.editor.config.onchange=function(e){
				console.log(e);
				var reg = /<[^<>]+>/g; //去标签
				var value = e.replace(reg, "");
				      value = value.replace(/&nbsp;/gi, ""); //将空格全部替换
				    
					 let n= value.length; //文字长度显示
					
					 if(n<=80){
						_this.TiLength=n;
						_this.planDoctor=e;
						
					 }else{
						 _this.$nextTick(() => {
							 
						         _this.editor.txt.text(_this.planDoctor);
						 }); 
					 }
					
				console.log(value.length);
			}
			this.editor.config.menus = [
				'head', // 标题
				'bold', // 粗体
				'fontSize', // 字号
				'fontName', // 字体
				'italic', // 斜体
				'underline', // 下划线
				'strikeThrough', // 删除线
				'foreColor', // 文字颜色
				// ‘backColor’, // 背景颜色
				// ‘link’, // 插入链接
				// ‘list’, // 列表
				// ‘justify’, // 对齐方式
				// ‘quote’, // 引用
				// ‘emoticon’, // 表情
				// ‘image’, // 插入图片
				// ‘table’, // 表格
				// ‘video’, // 插入视频
				// ‘code’, // 插入代码
				// ‘undo’, // 撤销
				// ‘redo’ // 重复
			]
			this.editor.create();
			
			this.editor1 = new EE('#zlcon1')
			
			let ins="";
			this.editor1.config.onchange=function(e){
				console.log(e);
				var reg = /<[^<>]+>/g; //去标签
				var value = e.replace(reg, "");
				      value = value.replace(/&nbsp;/gi, ""); //将空格全部替换
				    
					 let n= value.length; //文字长度显示
					 console.log(n);
					 if(n<35){
						 _this.showBTYWarn=false;
						_this.BuTongYiwordNumber=n;
						ins=e;
						
					 }else if(n==35){
						 _this.showBTYWarn=true;
						 _this.BuTongYiwordNumber=n;
						 ins=e;
					 }
					 else{
						 _this.$nextTick(() => {
								_this.showBTYWarn=true;
						         _this.editor1.txt.text(ins);
						 }); 
					 }
					
				
			}
			this.editor1.config.menus = [
			 'head', // 标题
			 'bold', // 粗体
			 'fontSize', // 字号
			 'fontName', // 字体
			 'italic', // 斜体
			 'underline', // 下划线
			 'strikeThrough', // 删除线
			 'foreColor', // 文字颜色
			 // ‘backColor’, // 背景颜色
			 // ‘link’, // 插入链接
			 // ‘list’, // 列表
			 // ‘justify’, // 对齐方式
			 // ‘quote’, // 引用
			 // ‘emoticon’, // 表情
			 // ‘image’, // 插入图片
			 // ‘table’, // 表格
			 // ‘video’, // 插入视频
			 // ‘code’, // 插入代码
			 // ‘undo’, // 撤销
			 // ‘redo’ // 重复
			 ]
			 this.editor1.create();
			this.ruleForm.oid=sessionStorage.getItem("oid");
			this.$store.commit("setYsmStepIndex",3);
			document.getElementsByClassName("fun1")[0].setAttribute("class","fun1 sub-menue1 visible")
			document.getElementsByClassName("fun")[0].setAttribute("class","fun sub-menue")
			document.getElementsByClassName("fun2")[0].setAttribute("class","fun2 sub-menue2");
			document.getElementsByClassName("fun3")[0].setAttribute("class","fun3 sub-menue3")	
			//设置返回路径
			this.$store.commit("setBackLocation","/ysmmain/ysmorder/ysmordertwo");
		},
		created:function(){
			//如果是编辑订单要获取oid的值
			let oid=sessionStorage.getItem("oid");
			console.log(oid);
			if(oid!="undefined"&&oid!=''&&oid!=null&&oid!=undefined){
				this.ruleForm.oid=oid;
				//根据oid查询订单信息
				this.$axios.get("/client/order/getOrderInfo",{
					params:{
						oid:this.ruleForm.oid
					}
				}).then(res=>{
					let orderInfo=res.data.data;
					console.log(orderInfo);
					this.status=orderInfo.status;
					//回显需要什么类型的治疗
					console.log(orderInfo.plan);
					console.log(orderInfo.plan.leixing);
					console.log(orderInfo.plan.leixing=="纯粹的美学治疗");
					if(orderInfo.plan.leixing=="纯粹的美学治疗"){
						this.mxchecked=true;
						this.zjchecked=false;
					}else if(orderInfo.plan.leixing=="正畸治疗"){
						this.mxchecked=false;
						this.zjchecked=true;
					}
					//治疗目标
					this.editor.txt.text(orderInfo.plan.mubiao)
					this.mubiao=orderInfo.plan.mubiao;
					if(this.status==2){
						//根据oid查询目标列表
						this.$axios.get("/client/order/queryGoals", {
							params: {
								oid: this.ruleForm.oid
							}
						}).then(res => {
							let orderInfo1 = res.data.data;
							console.log(orderInfo1);
							this.zhiLiaoMuBiao = res.data.data.goals;
							this.showTongyi=orderInfo1.showTongyi;
							this.showWenzi=orderInfo1.showWenzi;
						}).catch(err => {
							console.log(err);
						});
					}
				}).catch(err=>{
					console.log(err);
				});
				
				
			}else{
				this.$alert('请填写患者信息', '提示', {
					confirmButtonText: '确定',
					callback: action => {
						this.$router.push("/ysmmain/ysmorder/ysmorderone");
					}
				});
				
			}
		}
	}
</script>

<style scoped="scoped">
	.ysm-order-three{
		margin: 0px auto;
		width: 80%;
		display: flex;
		margin-top: 3.125rem;
		padding-bottom: 5.125rem;
		justify-content: space-around;
		/* border: 1px solid red; */
		
	}
	h3{
		font-family: 'themixitalic';
		font-weight: 400;
		line-height: 1.1;
		color: #af9f73;
		margin-bottom: 20px;
		font-size: 24px;
	}
	.left{
	display: flex;
	width: 30rem;
	position: relative;
	justify-content: flex-start;
	}
	
	#zlcon div{
		z-index: 0 !important;
	}
	#zlcon1{
		z-index: 0 !important;
	}
	
	/* 右边 */
	.right{
		/* position: relative;
		left: 9.55rem; */
		/* width: 560px; */
		
	}
	.con{
		/* width: 560px; */
		width: 560px;
		position: relative;
		left: 30px;
	}
	/* 按钮 */
	.nextStep{
		background-color: #af9f73 !important;
		border-color: #af9f73 !important;
		color: #fff !important;
		min-width: 7.5rem;
	}
	.back{
		min-width: 7.5rem;
		background-color: transparent;
		border-color: #af9f73 !important;
		color: #af9f73 !important;
	}
	.wordNumber {
		text-align: right;
		position: relative;
		left: 230px;
	  }
</style>
